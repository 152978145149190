import React, { useEffect, useState } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Label, CustomInput } from 'reactstrap';

import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import moment from 'moment';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { firestore } from '../../../../helpers/Firebase';
import { NotificationManager } from "../../../../components/common/react-notifications";
import { accessTypesObject, deepCompareArray } from '../../../../helpers/Utils';
import AddCar from './AddCar';
import DetailsTable from './receipt';
import { useSelector } from 'react-redux';

const statusOptions = [
  { label: 'Pending', value: 'new', key: 'new' },
  { label: 'Accepted', value: 'accepted', key: 'accepted' },
  { label: 'Active', value: 'active', key: 'active' },
  { label: 'Completed', value: 'completed', key: 'completed' },
  { label: 'Rejected', value: 'rejected', key: 'rejected' },
  { label: 'Canceled', value: 'canceled', key: 'canceled' },
]

const cancelReasons = [
  'Service Provider Delay',
  'Service Provider Vehicle Issues',
  'Service Provider Unresponsive',
  'Customer Unavailable',
  'Other'
];

const DatepickerInput = ({ ...props }) => (
  <input type="text" {...props} readOnly />
);
const ManageOrder = ({ selectedBooking, washers, providers, manageOrder, modifiers, bookings, services, dismiss, updateAppointment }) => {
  const [confirmUpdate, setConfirmUpdate] = useState(false)
  const [addCar, setAddCar] = useState(false)
  const { user } = useSelector(state => state.authUser)

  const [newOrder, setNewOrder] = useState(JSON.parse(JSON.stringify(selectedBooking)))
  const [status, setStatus] = useState(false)
  const [service, setService] = useState({})
  const [orderDate, setOrderDate] = useState(false)
  const [washer, setWasher] = useState(false)
  const [provider, setProvider] = useState({})
  const [filteredWashers, setFilteredWashers] = useState([])
  const [price, setPrice] = useState(0)
  const [duration, setDuration] = useState(0)
  const [onlinePayment, setOnlinePayment] = useState(selectedBooking?.price?.collectOutstandingOnline || false)

  const [car, UpdateCars] = useState(newOrder.carDetail);
  const [addonsOptimized, setAddons] = useState({})

  const createNotification = (className, msg) => {
    let cName = className || "";
    return (
      NotificationManager.error(
        msg,
        "Error",
        2000,
        null,
        cName
      )
    )
  };
  useEffect(() => {
    setProvider(Object.values(providers).find(rec => rec.key === newOrder?.sp_id))
    setWasher(Object.values(washers).find(rec => rec.key === newOrder?.washer_id) || false)
    setService(Object.values(services).find(rec => rec.key === newOrder?.service_id))
    setStatus(newOrder.status)
    setOrderDate(new Date(newOrder?.bookingDate))
    setNewOrder(newOrder)



    if (newOrder.addons) {
      let addons = newOrder.addons?.reduce((cars, currentAddon) => {
        if (currentAddon.cars) {
          Object.keys(currentAddon.cars).map(carKey => {
            let car = currentAddon.cars[carKey]
            if (cars[car.car_model]) {
              cars[car.car_model][currentAddon.id] = { name: currentAddon.name, type: currentAddon.type, price: car.price, category: car.category, count: car.count }
              return cars
            } else {
              cars[car.car_model] = { [currentAddon.id]: { name: currentAddon.name, type: currentAddon.type, price: car.price, category: car.category, count: car.count } }
              return cars
            }
          })
        }
        return cars
      }, {})
      setAddons(addons)
    }
  }, [newOrder, providers, washers, services])


  useEffect(() => {
    setFilteredWashers(Object.values(washers).filter(rec => rec.sp_id === provider.key))
  }, [washers, provider])

  useEffect(() => {
    let price = 0
    let durationMin = 0

    if (car.length) {
      let variationPrice = 0
      car.forEach(rec => {
        let id = Object.values(modifiers).find(record => record?.name === rec?.modelNumber?.category)?.key
        let modifierDuration = newOrder?.washingType === 'waterlessWash' ? washer?.activeServices?.[service?.key]?.[id]?.waterlessDuration : washer?.activeServices?.[service?.key]?.[id]?.duration

        if (modifierDuration) {
          const hours = modifierDuration.split(':')[0];
          const minutes = modifierDuration.split(':')[1];
          durationMin = durationMin + (parseInt(hours) * 60) + (parseInt(minutes));
        }
        // price = price + (Number(washer?.activeServices?.[service?.key]?.[id]?.price || 0) * 1)

        price = price + ((newOrder?.washingType === 'waterlessWash' ? Number(washer?.activeServices?.[service?.key]?.[id]?.waterlessPrice || 0) : Number(washer?.activeServices?.[service?.key]?.[id]?.price || 0)) * 1)


        // eslint-disable-next-line
        if (newOrder?.variation) {
          let variPrice = newOrder?.price?.variPrice?.[id] || 0
          variationPrice = variationPrice + variPrice
          newOrder.price.variationPrice = variationPrice || 0
        }


        // price.variationPrice = variationPrice
        let addonsPrice = newOrder?.addons?.reduce((total, currentValue) => {
          let cumPrice = 0
          Object.values(currentValue.cars).map(item => {
            return (cumPrice += Number(item?.price || 0) * Number(item?.count || 0))
          })
          return ((total) + cumPrice)
        }, 0)
        newOrder.price.addonsPrice = addonsPrice || 0
        newOrder.price.servicePrice = price || 0
        newOrder.price.totalPrice = Number(price || 0) + Number(newOrder?.price?.addonsPrice || 0) + Number(newOrder?.price?.variationPrice || 0) - Number(newOrder?.price?.discount || 0)
        newOrder.price.services = washer?.activeServices?.[service?.key]
        newOrder.price.subTotal = newOrder.price.totalPrice
        newOrder.totalPrice = Number(price || 0) + Number(newOrder?.price?.addonsPrice || 0) + Number(newOrder?.price?.variationPrice || 0)
        if ((!deepCompareArray(newOrder?.carDetail, car) || selectedBooking?.price?.outstanding)) {
          newOrder.price.outstanding = Number(selectedBooking?.price?.outstanding || 0) + Number(newOrder?.price?.totalPrice) - Number(selectedBooking?.price?.totalPrice)
        }
        setNewOrder(newOrder)
        setPrice(price)
        setDuration(durationMin)
      })
    }
    // eslint-disable-next-line
  }, [car, service, washer, modifiers, newOrder, selectedBooking])
  function removeCar(addons, carModel) {
    // eslint-disable-next-line
    addons.map((addon, i) => {
      const cars = addon?.cars;
      if (cars?.[carModel]) {
        delete cars[carModel];
        if (Object.keys(cars).length === 0) {
          addons.splice(i, 1);
        }
      }
      if (Object.keys(addon?.cars || {}).length === 0) {
        addons.splice(i, 1);
      }
    })
    return addons
  }
  const _getReservedSlot = (washer) => {
    if (washer) {
      const slots = [];
      Object.values(bookings).map(rec => {
        let { bookingDate, bookingTime } = rec
        if (['new', 'accepted', 'active'].includes(rec.status) && washer.key === rec.washer_id && moment(bookingDate).isAfter(moment())) {
          let duration = moment.duration(moment(bookingTime.endTime).diff(moment(bookingTime.startTime))).asMinutes();
          let reservedSlot = bookingDate;
          while (moment(reservedSlot).isBefore(moment(bookingDate).add(duration, 'minutes'))) {
            slots.push({ reservedSlot, bookingTime })
            reservedSlot = moment(reservedSlot).add(15, 'minutes').valueOf()
          }
        }
        return rec
      })
      return slots
    }
  };
  const _filterPassedTime = () => {
    let today = moment(orderDate || new Date()).format('dddd')

    const availableTime =
      (washer?.timings?.[today]?.isOpen &&
        washer?.timings?.[today]?.timings) ||
      [];
    const slots = [];
    availableTime.map(item => {


      let startTime = moment(item.startTime)
        .set('year', moment(orderDate).year())
        .set('month', moment(orderDate).month())
        .set('date', moment(orderDate).date()).seconds(0).milliseconds(0);
      let endTime = moment(item.endTime)
        .set('year', moment(orderDate).year())
        .set('month', moment(orderDate).month())
        .set('date', moment(orderDate).date()).seconds(0).milliseconds(0);


      let slot = startTime.valueOf();
      while (moment(slot).isBefore(endTime)) {
        slots.push(slot)
        slot = moment(slot).add(15, 'minutes').valueOf()
      }
      return item
    })

    let filterSlots = slots.filter(rec => !moment(rec).isSame(moment(washer?.busyTimings?.startTime)) && !moment(rec).isBetween(moment(washer?.busyTimings?.startTime), moment(washer?.busyTimings?.endTime)))

    return filterSlots
  };

  const updateOrder = async () => {

    const newItem = {
      canceledBy: newOrder.canceledBy || (newOrder.status === 'canceled' || newOrder.status === 'rejected' ? 'Super Admin' : ''),
      addons: newOrder.addons || [],
      bookingDate: newOrder.bookingDate,
      bookingTime: newOrder.bookingTime,

      updated: Date.now(),

      sp_id: provider.key,
      sp_name: provider.name,

      status: status,

      washer_id: washer.key,
      washer_name: washer.name,

      paymentMethod: Number(newOrder?.price?.totalPrice) !== Number(selectedBooking?.price?.totalPrice) ? {
        ...selectedBooking.paymentMethod,
        amount: newOrder?.price?.totalPrice,
        currency: "AED"
      } : selectedBooking.paymentMethod,
      paymentType: Number(newOrder?.price?.totalPrice) !== Number(selectedBooking?.price?.totalPrice) ? 'cash' : selectedBooking.paymentType,

      price: newOrder.price,
      totalPrice: newOrder.price.totalPrice,
    };
    if (newOrder?.cancelReason) {
      newItem.cancelReason = newOrder.cancelReason
    }
    if (newOrder?.cancellationApplied) {
      newItem.cancellationApplied = newOrder?.cancellationApplied || false
    }
    if (onlinePayment) {
      newItem.price.collectOutstandingOnline = onlinePayment
    }
    if (!deepCompareArray(newOrder?.carDetail, car)) {
      newItem.carDetail = car
    }

    if (selectedBooking.bookingDate !== newOrder.bookingDate) {
      newItem.adminReschedule = Date.now()
      let customer = await firestore().collection('users').doc(selectedBooking?.customer_id).get()
      if (customer?.data()?.fcm_token) {
        firestore().collection('notifications').add({
          user: selectedBooking?.customer_id,
          to: customer?.data()?.fcm_token,
          title: "Appointment Rescheduled ",
          body: `Your appointment has been rescheduled from ${moment(selectedBooking.bookingDate).format('DD/MM/YYYY hh:mm A')} to ${moment(newOrder.bookingDate).format('DD/MM/YYYY hh:mm A')}`,
          read: false,
        })
      }

    }
    let logs = [
      selectedBooking.sp_id !== provider.key && {
        title: 'reAssignProvider',
        created: Date.now(),
        oldProviderName: selectedBooking.sp_name,
        oldProviderId: selectedBooking.sp_id,
        provider_name: provider.name,
        provider_id: provider.key,
        createdBy: accessTypesObject?.[user?.accessType].name,
        createdByName: user?.displayName
      },
      selectedBooking.washer_id !== washer.key && {
        title: 'reAssignWorker',
        created: Date.now(),
        oldWasherName: selectedBooking.washer_name,
        oldWasherId: selectedBooking.washer_id,
        washer_name: washer.name,
        washer_id: washer.key,
        createdBy: accessTypesObject?.[user?.accessType].name,
        createdByName: user?.displayName
      },
      selectedBooking.bookingDate !== newOrder.bookingDate && {
        title: 'rescheduleDate',
        created: Date.now(),
        oldBookingDate: selectedBooking.bookingDate,
        bookingDate: newOrder.bookingDate,
        createdBy: accessTypesObject?.[user?.accessType].name,
        createdByName: user?.displayName
      },
      selectedBooking.status !== status && {
        title: status === 'canceled' ? "canceledAppointment" : status === 'rejected' ? "rejectedAppointment" : 'statusChanged',
        created: Date.now(),
        oldsStatus: selectedBooking.status,
        status: status,
        createdBy: accessTypesObject?.[user?.accessType].name,
        createdByName: user?.displayName
      },
      !deepCompareArray(selectedBooking?.carDetail, car) && {
        title: 'vehicleModified',
        created: Date.now(),
        oldVehicle: selectedBooking?.carDetail,
        newVehicle: car,
        createdBy: accessTypesObject?.[user?.accessType].name,
        createdByName: user?.displayName
      },
      newItem.cancellationApplied && selectedBooking.cancellationApplied !== newItem.cancellationApplied && {
        title: 'cancellationFeesApplied',
        created: Date.now(),
        createdBy: accessTypesObject?.[user?.accessType].name,
        createdByName: user?.displayName
      }].filter(rec => rec)

    if (Object.keys(selectedBooking)?.length > 0 && selectedBooking.sp_id !== provider.key) {
      let bookingKey = firestore().collection('bookings').doc().id

      newItem.key = bookingKey
      newItem.id = bookingKey
      newItem.bookingId = bookingKey

      newItem.created = Date.now()
      newItem.orderNumber = Math.floor(Math.random(new Date().getTime()) * 90000000) + 10000000
      let newBooking = { ...selectedBooking, ...newItem }
      delete newBooking.price.addonsPrice
      delete newBooking.price.discount
      delete newBooking.price.code
      delete newBooking.cancelReason
      delete newBooking.canceledBy
      delete newBooking.superAdminComment

      firestore().collection('bookings').doc(bookingKey).set(newBooking).then(function () {

        firestore().collection('logs').doc(bookingKey).set({
          data: logs,
          updated: Date.now()
        }, { merge: true }).catch(error => error)

        firestore().collection('bookings').doc(selectedBooking.key).update({ status: 'canceled', canceledBy: user?.displayName + ` (${accessTypesObject?.[user?.accessType].name})`, updated: Date.now() }).then(function () {
          updateAppointment()
          setConfirmUpdate(false)
          firestore().collection('logs').doc(selectedBooking.key).set({
            data: firestore.FieldValue.arrayUnion(
              {
                title: "canceledAppointment",
                created: Date.now(),
                oldsStatus: selectedBooking.status,
                status: 'canceled',
                createdBy: accessTypesObject?.[user?.accessType].name,
                createdByName: user?.displayName
              }
            ),
            updated: Date.now()
          }, { merge: true }).catch(error => error)

        }).catch(function (error) {
          console.error("Error writing document: ", error);
        });
      }).catch(function (error) {
        console.error("Error writing document: ", error);
      });

    } else {
      firestore().collection('bookings').doc(selectedBooking.key).update(newItem).then(function () {
        updateAppointment()
        setConfirmUpdate(false)
        logs.map(rec => {
          return firestore().collection('logs').doc(selectedBooking.key).set({
            data: firestore.FieldValue.arrayUnion(rec),
            updated: Date.now()
          }, { merge: true }).catch(error => error)
        })
      }).catch(function (error) {
        console.error("Error writing document: ", error);
      });

    }
  };

  return (
    <Modal
      isOpen={manageOrder}
      backdrop="static"
      size="lg">
      <ModalHeader >{"Manage Order"}</ModalHeader>
      <ModalBody>
        <Label>Select Service Provider</Label>
        <Select
          components={{ Input: CustomSelectInput }}
          className="react-select"
          classNamePrefix="react-select"
          name="form-field-name"
          options={Object.values(providers)}
          value={provider}
          onChange={val => {
            if (provider.key !== val.key) {
              newOrder.sp_id = val.key
              newOrder.sp_name = val.name
              newOrder.sp_name_ar = val.name_ar
              delete newOrder.addons
              delete newOrder.price.addonsPrice
              delete newOrder.price.discount
              delete newOrder.price.code
              delete newOrder.price.variPrice
              delete newOrder.price.variationPrice
              delete newOrder.superAdminComment
              delete newOrder.variation
              setAddons({})
              setNewOrder(newOrder)
              setProvider(val)
              setFilteredWashers(Object.values(washers).filter(rec => rec.sp_id === val.key))
              setWasher(false)
              setStatus('new')

              setOrderDate(false)
            }
          }}
        />
        <Label className="mt-2">Select Washer</Label>
        <Select
          components={{ Input: CustomSelectInput }}
          className="react-select"
          classNamePrefix="react-select"
          name="form-field-name"
          options={filteredWashers}
          value={washer}
          onChange={val => {
            if (washer.key !== val.key) {
              newOrder.washer_id = val.key
              newOrder.washer_name = val.name

              setNewOrder(newOrder)
              setWasher(val)
            }
          }}
        />

        <Label className="mt-2">Select Date</Label>
        <DatePicker
          popperClassName="redatepicker"
          customInput={<DatepickerInput />}
          selected={(moment(orderDate || new Date()).toDate())}

          onChange={date => {
            setOrderDate(date)
            newOrder.bookingDate = moment(date).valueOf()
            newOrder.bookingTime.startTime = moment("1955-01-05T" + moment(date).format('HH:mm')).valueOf()
            newOrder.bookingTime.endTime = moment(newOrder.bookingTime.startTime).add(duration, 'minutes').add(30, 'minutes').valueOf()
            setNewOrder(newOrder)
          }}
          excludeTimes={washer && _getReservedSlot(washer).map(rec => {
            return (moment(orderDate || new Date()).toDate().getDate() === moment(rec.reservedSlot).toDate().getDate()) && rec.reservedSlot
          })}
          includeTimes={_filterPassedTime().map(rec => {
            return rec
          })}
          showTimeSelect
          timeFormat="hh:mm a"
          timeIntervals={15}
          timeCaption="time"
          dateFormat="dd/MM/yyyy hh:mm a"
        />

        <Label className="mt-2">Select Status</Label>
        <Select
          components={{ Input: CustomSelectInput }}
          className="react-select"
          classNamePrefix="react-select"
          name="form-field-name"
          options={statusOptions}
          value={statusOptions.find(rec => rec.key === status)}
          onChange={val => {
            if (status !== val.key) {
              newOrder.status = val.key

              newOrder.canceledBy = (val.key === 'canceled' || val.key === "rejected") ? (user?.displayName + ` (${accessTypesObject?.[user?.accessType].name})`) : ''

              setNewOrder(newOrder)
              setStatus(val.key)
            }
          }}
        />
        {['canceled', 'rejected'].includes(newOrder.status) ?
          <>
            <Label className="mt-2">Cancel / Reject Reason</Label>
            <Select
              components={{ Input: CustomSelectInput }}
              className="react-select"
              classNamePrefix="react-select"
              name="form-field-name"
              options={cancelReasons?.map(rec => ({ key: rec, label: rec, value: rec })) || []}
              value={cancelReasons?.map(rec => ({ key: rec, label: rec, value: rec }))?.find(rec => rec.key === newOrder?.cancelReason) || { key: 'Other', label: 'Other', value: 'Other' }}
              onChange={val => {
                if (val.key === 'Other') {
                  setNewOrder({ ...newOrder, cancelReason: '' })
                } else {
                  setNewOrder({ ...newOrder, cancelReason: val.key })
                }
              }}
            />
            {!cancelReasons.includes(newOrder?.cancelReason) || newOrder?.cancelReason === 'Other' ?
              <>
                <Label className="mt-2">Type the reason for cancelation </Label>
                <textarea
                  className="form-control"
                  placeholder='Other - Please provide details.'
                  value={newOrder?.cancelReason || ''}
                  onChange={(e) => {
                    setNewOrder({ ...newOrder, cancelReason: e.target.value })
                  }}
                />
              </> : ''}
          </>
          : ''}

        {['canceled', 'rejected'].includes(newOrder.status) ?
          <CustomInput
            className="custom-checkbox mb-0 mt-2 d-inline-block"
            type="checkbox"
            id="checkAll"
            checked={newOrder.cancellationApplied}
            onChange={(e) => {
              setNewOrder({ ...newOrder, cancellationApplied: e.target.checked })
            }}
            label={<div style={{ cursor: 'pointer', fontWeight: '700' }}>Apply Cancellation Fees</div>}
          />
          : ''}


        <AddCar addCar={addCar} submit={(e) => {
          let cars = JSON.parse(JSON.stringify(car)) || []
          cars.push(e)
          UpdateCars(cars)
          setAddCar(false)
        }} dismiss={() => setAddCar(false)} />
        {(newOrder?.variation ? true : price && price !== 0) ?
          <div>
            <div className="mt-4 mb-2 d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
              <h5 >Details</h5>
              {/* {!newOrder?.subscription && <Button size='sm' color="primary" onClick={() => {
                setAddCar(true)
              }} >
                Add Car
              </Button>} */}
            </div>
            <DetailsTable
              modifiers={modifiers}
              carDetail={car}
              selectedBooking={newOrder}
              washer={washer}
              service={service.key}
              price={newOrder?.price}
              addonsOptimized={addonsOptimized}
              manageOrder={true}
              removeCar={(carModel) => {
                let cars = (JSON.parse(JSON.stringify(car)) || []).filter(c => (c?.car_model + " " + c?.modelNumber?.model) !== carModel)
                if (newOrder?.addons?.length) {
                  let addons = removeCar(newOrder?.addons, carModel)
                  setNewOrder({ ...newOrder, addons })
                }

                UpdateCars(cars)
              }}
              onlinePayment={onlinePayment}
              setOnlinePayment={(e) => {
                setOnlinePayment(e)
              }} />


            {/* <Row>
              <Colxx xxs="12" >
                {car?.map((rec, index) => {
                  let category = rec?.modelNumber?.category
                  let carModel = rec?.car_model + " " + rec?.modelNumber?.model


                  let id = Object.values(modifiers).find(record => record?.name === category)?.key
                  let servicePrice = ((washer?.activeServices?.[service?.key]?.[id]?.price * 1) || 0) + (newOrder.price?.variPrice?.[id] || 0)
                  let Variations = { darkness: 'Tint Level', frontSide: 'Selected Sides', brand: 'Package', heatRejection: 'Heat Rejection' }

                  return (
                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ flex: '2' }}>
                        <div className="mt-2 mb-1" style={{ fontWeight: '700' }}>{carModel + " - " + category}</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '12px' }}>
                          <div><span style={{ fontWeight: '600' }}>{"1x "}</span> {newOrder.service_name}</div>
                          <div style={{ fontWeight: '700' }}>{servicePrice} AED</div>

                        </div>
                        {newOrder?.variation && Object.keys(newOrder?.variation).length &&
                          Object.keys(newOrder?.variation).map((variation, index) => {
                            return (
                              <div className="mb-1" key={index} style={{ marginLeft: '12px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '12px', paddingLeft: '19px' }}>
                                <div >{Variations?.[variation]}: </div>
                                <div >{newOrder?.variation[variation]}</div>
                              </div>
                            )
                          })
                        }
                        {addonsOptimized?.[carModel] && Object.values(addonsOptimized[carModel]).map((addon, index) => {
                          return (
                            <div key={index} style={{ marginLeft: '12px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div ><span style={{ fontWeight: '600' }}>{addon.count + "x "}</span> {addon.name} </div>
                              <div style={{ fontWeight: '700' }}>{Number(addon.count) * Number(addon.price)} AED</div>
                            </div>
                          )
                        })
                        }
                      </div>
                      {(car.length > 1 && newOrder?.paymentType === 'cash' && !['completed', 'rated', 'canceled', 'rejected'].includes(selectedBooking?.status)) ?
                        <i className="simple-icon-trash ml-2 mt-2"
                          style={{ cursor: 'pointer', flex: '0.2', fontSize: '16px', textAlign: 'end', color: '#dc3545' }}
                          onClick={() => {

                            let cars = (JSON.parse(JSON.stringify(car)) || []).filter(c => (c?.car_model + " " + c?.modelNumber?.model) !== carModel)
                            if (newOrder?.addons?.length) {
                              let addons = removeCar(newOrder?.addons, carModel)
                              setNewOrder({ ...newOrder, addons })
                            }

                            UpdateCars(cars)
                          }} /> : ''}
                    </div>
                  )
                })
                }
              </Colxx>
            </Row>


            {newOrder.price?.code && newOrder?.price?.discount && <Row>
              <Colxx xxs="12" className="mt-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                <div style={{ flex: '2', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ fontWeight: '700' }}>Discount:</div>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    {newOrder.price?.code === 'loyalty' ? <div >Loyalty points</div> : <div >Promo Code: {newOrder.price?.code}</div>}

                    <div style={{ fontWeight: '700' }}>{newOrder.price?.discount} AED</div>
                  </div>
                </div>
                {(car.length > 1 && newOrder?.paymentType === 'cash' && !['completed', 'rated', 'canceled', 'rejected'].includes(selectedBooking?.status)) ? <div className="ml-2 mt-2"
                  style={{ flex: '0.2' }}
                  onClick={() => {
                  }} /> : ''}
              </Colxx>
            </Row>

            }

            {newOrder?.price?.outstanding ? <Row>
              <Colxx xxs="12" className="mt-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                <div style={{ flex: '2', }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ fontWeight: '700' }}>Outstanding Amount:</div>
                    <div style={{ fontWeight: '700' }}>{newOrder.price.outstanding} AED</div>
                  </div>
                  {newOrder.price.outstanding > 0 ? <CustomInput
                    className="custom-checkbox d-inline-block"
                    type="checkbox"
                    id="checkAll"
                    checked={onlinePayment}
                    onChange={(e) => {
                      setOnlinePayment(e.target.checked)
                    }}
                    label={<div style={{ cursor: 'pointer' }}>Outstanding amount will be collected online </div>}
                  /> : ''}
                </div>
                {(car.length > 1 && newOrder?.paymentType === 'cash' && !['completed', 'rated', 'canceled', 'rejected'].includes(selectedBooking?.status)) ? <div className="ml-2 mt-2"
                  style={{ flex: '0.2' }}
                  onClick={() => {
                  }} /> : ''}
              </Colxx>
            </Row> : ''}
            <Row>
              <Colxx xxs="12" className="mt-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                <div style={{ flex: '2', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ fontWeight: '700' }}>Total Price:</div>
                  <div style={{ fontWeight: '700' }}>{newOrder.price.totalPrice} AED</div>
                </div>
                {(car.length > 1 && newOrder?.paymentType === 'cash' && !['completed', 'rated', 'canceled', 'rejected'].includes(selectedBooking?.status)) ? <div className="ml-2 mt-2"
                  style={{ flex: '0.2' }}
                  onClick={() => {
                  }} /> : ''}
              </Colxx>
            </Row> */}
          </div> : ''
        }
      </ModalBody>
      <ModalFooter>
        <Button color="danger" outline onClick={() => {
          setWasher(false)
          setProvider({})
          setStatus(false)
          setFilteredWashers([])

          dismiss()
        }}>
          Dismiss
        </Button>
        <Button
          disabled={(newOrder?.variation ? false : price === 0)}
          color="primary" outline onClick={() => {
            if (provider && washer && orderDate && status) {
              setConfirmUpdate(true)
            } else {
              if (!provider) {
                return createNotification("error", "Kindly select service provider")
              }
              if (!washer) {
                return createNotification("error", "Kindly select worker")

              }
              if (!orderDate) {
                return createNotification("error", "Kindly select order date")

              }
              if (!status) {
                return createNotification("error", "Kindly select status")
              }
            }
          }} >
          Confirm
        </Button>{" "}
      </ModalFooter>
      <Modal
        backdrop="static"
        isOpen={confirmUpdate}>
        <ModalHeader>Confirm Update</ModalHeader>
        <ModalBody>Are you sure you want to apply changes to this appointment?</ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={() => {
            setConfirmUpdate(false)
          }}>
            Cancel
          </Button>
          <Button
            color="primary" outline onClick={() => {
              updateOrder()
            }} >
            Confirm Update
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </Modal>
  );
};

export default ManageOrder;