import axios from 'axios';
import { NotificationManager } from "../components/common/react-notifications";
import { defaultDirection } from "../constants/defaultValues";
import { firestore } from './Firebase';
import moment from 'moment';

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};


export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
}

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes()
}

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl"
  };
};

export const setDirection = localValue => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};
export const deviceWidth = () => {
  const width = window.innerWidth;
  const breakpoint = 820;
  return width < breakpoint ? 'mobile' : 'desktop';
}
export function getFirstCharacters(string) {
  if (!string) {
    return ''
  }
  let extractedString = [];
  const words = string?.split(" ");
  for (let i = 0; i < words.length; i++) {
    const firstChar = words[i].trim()[0]?.toUpperCase();
    if (firstChar) {
      extractedString.push(firstChar);
      if (extractedString.length === 2) {
        break;
      }
    }
  }
  return extractedString.join("");
}
export const createNotification = (className, msg, title) => {
  let cName = className || "";

  switch (className) {
    case "primary":
      return NotificationManager.primary(
        title,
        msg,
        3000,
        null,
        null,
        cName
      );
    case "secondary":
      return NotificationManager.secondary(
        title,
        msg,
        3000,
        null,
        null,
        cName
      );
    case "info":
      return NotificationManager.info("Info message", "", 3000, null, null, cName);
    case "success":
      return NotificationManager.success(
        title,
        msg,
        3000,
        null,
        null,
        cName
      );
    case "warning":
      return NotificationManager.warning(
        title,
        msg,
        3000,
        null,
        null,
        cName
      );
    case "error":
      return NotificationManager.error(
        msg,
        title,
        6000,
        null,
        cName
      )
    default:
      NotificationManager.info("Info message");
      break;
  }
};
export function makeid(length) {
  var result = Math.floor(Math.random(new Date().getTime()) * 90000000) + 10000000
  return result;
}

export async function getPlaceId(lat, Lng) {
  const result = await axios.get(`https://nominatim.openstreetmap.org/reverse.php?lat=${lat}&lon=${Lng}&zoom=21&format=jsonv2&addressdetails=1`);
  let place_id = result?.data?.place_id

  const result2 = await axios.get(`https://nominatim.openstreetmap.org/details.php?place_id=${place_id}&addressdetails=1&format=json`);
  // let data = result2?.data?.address?.find(r => ['boundary', 'place'].includes(r.class) && ['town', 'suburb', 'administrative', 'neighbourhood'].includes(r.type));
  let data = result2?.data?.address?.find(r => ['boundary', 'place', 'aeroway'].includes(r.class) && ['town', 'suburb', 'administrative', 'neighbourhood', 'aerodrome', 'residential'].includes(r.type));


  return data?.place_id;
}
export const accessTypes = [
  "superAdmin", "finance", "customerService", "marketing"
]

export const accessTypesObject = {
  "superAdmin": { name: 'Super admin' },
  "finance": { name: 'Finance' },
  "customerService": { name: 'Customer Service' },
  'admin': { name: 'Provider' },
  'marketing': { name: 'Marketing' },
  'washer': { name: 'Worker' },
}
export const accessTypesArray = [
  'Super admin',
  'Admin',
  'Finance',
  'Customer Service',
  'Marketing',
  'Provider',
]
export const sortTimings = (timings) => {
  const sorter = {
    "saturday": 1,
    "sunday": 2,
    "monday": 3,
    "tuesday": 4,
    "wednesday": 5,
    "thursday": 6,
    "friday": 7,
  };

  let tmp = [];
  Object.keys(timings).forEach(function (key) {
    let value = timings[key];
    let index = sorter[key.toLowerCase()];
    tmp[index] = {
      key: key,
      value: value
    };
  });

  let orderedData = {};
  tmp.forEach(function (obj) {
    orderedData[obj.key] = obj.value;
  });
  return orderedData
}

export const colors = [{ key: 1, value: 'White', label: 'White' }, { key: 2, value: 'Silver', label: 'Silver' }, { key: 3, value: 'Grey', label: 'Grey' }, { key: 4, value: 'Black', label: 'Black' }, { key: 5, value: 'Yellow', label: 'Yellow' }, { key: 6, value: 'Brown', label: 'Brown' }, { key: 7, value: 'Red', label: 'Red' }, { key: 8, value: 'Green', label: 'Green' }, { key: 9, value: 'Blue', label: 'Blue' }, { key: 10, value: 'Burgundy', label: 'Burgundy' }, { key: 11, value: 'Gold', label: 'Gold' }, { key: 12, value: 'Orange', label: 'Orange' }, { key: 13, value: 'Purple', label: 'Purple' }, { key: 14, value: 'Tan', label: 'Tan' }, { key: 15, value: 'Other', label: 'Other' }]
export const emirates = [{ key: 1, value: 'Abu Dhabi', label: 'Abu Dhabi' }, { key: 2, value: 'Ajman', label: 'Ajman' }, { key: 3, value: 'Dubai', label: 'Dubai' }, { key: 4, value: 'Fujairah', label: 'Fujairah' }, { key: 5, value: 'Ras Al Khaimah', label: 'Ras Al Khaimah' }, { key: 6, value: 'Sharjah', label: 'Sharjah' }, { key: 7, value: 'Umm Al Quwain', label: 'Umm Al Quwain' }]

export function deepCompareArray(a, b) {
  if (a === b) {
    return true;
  }

  if (a == null || b == null || a.length !== b.length) {
    return false;
  }

  // Sort arrays to ensure same order
  a = a.sort();
  b = b.sort();

  for (let i = 0; i < a.length; i++) {
    if (typeof a[i] !== typeof b[i]) {
      return false;
    }
    if (typeof a[i] === 'object') {
      if (!deepCompareObjects(a[i], b[i])) {
        return false;
      }
    } else if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
}

function deepCompareObjects(a, b) {
  if (a === b) {
    return true;
  }

  if (a == null || b == null || typeof a !== 'object' || typeof b !== 'object') {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    if (!b.hasOwnProperty(key)) {
      return false;
    }
    if (typeof a[key] === 'object') {
      if (!deepCompareObjects(a[key], b[key])) {
        return false;
      }
    } else if (a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}


export function deepCompare(arr1, arr2) {
  const diffs = [];

  arr1.forEach((obj1) => {
    const obj2 = arr2.find((o) => deepEquals(o, obj1));
    if (!obj2) {
      diffs.push({ ...obj1, origin: 'oldVehicle' });
    }
  });

  arr2.forEach((obj2) => {
    const obj1 = arr1.find((o) => deepEquals(o, obj2));
    if (!obj1) {
      diffs.push({ ...obj2, origin: 'newVehicle' });
    }
  });

  return diffs;
}

function deepEquals(a, b) {
  if (a === b) {
    return true;
  }

  if (a == null || typeof (a) != "object" || b == null || typeof (b) != "object") {
    return false;
  }

  const keysA = Object.keys(a).sort();
  const keysB = Object.keys(b).sort();

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (let i = 0; i < keysA.length; i++) {
    if (keysA[i] !== keysB[i]) {
      return false;
    }

    if (!deepEquals(a[keysA[i]], b[keysA[i]])) {
      return false;
    }
  }

  return true;
}

export function formatNumber(number) {
  if (Number(number) >= 1000) {
    var units = ['', 'K', 'M', 'B', 'T']; // Add more units as needed
    var unitIndex = 0;
    while (number >= 1000) {
      number /= 1000;
      unitIndex++;
    }
    return number?.toFixed(2) + units[unitIndex];
  } else {
    return number?.toString();
  }
}
export function getValueBetweenParentheses(inputString) {
  const regex = /\((.*?)\)/; // Regular expression to match the content between parentheses
  const match = regex.exec(inputString);
  return match && match[1] ? match[1] : inputString;
}
export function ContainsArabic(text) {
  // Define a regular expression pattern for Arabic script characters
  var arabicPattern = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;

  // Use the test method to check if the text contains Arabic characters
  return arabicPattern.test(text);
}

export function sortObjectByCategory(inputObject) {
  const sortedObject = {};
  Object.values(inputObject || {}).forEach(item => {
    if (!sortedObject[item.category]) {
      sortedObject[item.category] = { key: item.category, value: item.category, label: item.category, options: [item] };
    } else {
      sortedObject[item.category].options.push(item);
    }
  });

  return sortedObject;
}
export function getAllOptions(sortedObject) {
  const allOptions = [];

  Object.values(sortedObject).forEach(category => {
    allOptions.push(...category.options);
  });

  return allOptions;
}
export function filterSortedObject(sortedObject, selectedOptionIds) {
  const filteredObject = {};

  Object.keys(sortedObject).forEach(categoryKey => {
    const category = sortedObject[categoryKey];
    const filteredOptions = category.options.filter(option =>
      !selectedOptionIds.includes(option.key)
    );

    if (filteredOptions.length > 0) {
      filteredObject[category.key] = { key: category.key, value: category.key, label: category.key, options: filteredOptions };
    }
  });

  return filteredObject;
}

export function filterAndSortObjectByCategory(inputObject, selectedOptionIds) {
  const resultObject = {};

  Object.values(inputObject || {}).forEach(item => {
    if (!resultObject[item.category]) {
      resultObject[item.category] = { key: item.category, value: item.category, label: item.category, options: [item] };
    } else {
      resultObject[item.category].options.push(item);
    }
  });

  const filteredObject = {};

  Object.keys(resultObject || {}).forEach(categoryKey => {
    const category = resultObject[categoryKey];
    const filteredOptions = category?.options?.filter(option =>
      !selectedOptionIds?.includes(option.key)
    );

    if (filteredOptions.length > 0) {
      filteredObject[category.key] = { key: category.key, value: category.key, label: category.key, options: filteredOptions };
    }
  });

  return filteredObject;
}

export const compareContacts = (a, b) => {

  // const itemExpirationA = a?.exp;
  // const itemExpirationB = b?.exp;

  const itemUpdatedA = a?.updated;
  const itemUpdatedB = b?.updated;

  // if (itemExpirationA > itemExpirationB) {
  //   return 1;
  // } else if (itemExpirationA < itemExpirationB) {
  //   return -1;
  // } else {
  // If the item expiration dates are the same, compare the last message's created property
  if (itemUpdatedA < itemUpdatedB) {
    return 1;
  } else if (itemUpdatedA > itemUpdatedB) {
    return -1;
  } else {
    return 0;
    // }
  }
};


export const SearchChats = async ({ searchText, user }) => {
  let ref = firestore().collection('chats')
  if (user?.accessType === 'admin') {
    ref = ref.where('provider_id', '==', user?.uid)
  }
  let nameSnapshot = await ref.where('name', '>=', searchText)
    .where('name', '<=', searchText + '\uf8ff') // For case-insensitive search
    .limit(3)
    .get();
  let numberQuery = await ref.where('wa_id', '>=', searchText)
    .where('wa_id', '<=', searchText + '\uf8ff') // For case-insensitive search
    .limit(3)
    .get();
  let docs = [...numberQuery.docs, ...nameSnapshot.docs];
  return docs
}

export const getBannerName = (banner) => {
  let name = ''
  switch (banner?.type) {
    case 'url': case 'navigation':
      name = banner.url
      break
    case 'service': case 'serviceProvider':
      name = banner.name
      break
    default:
      name = banner.type
      break
  }
  return name
}

export async function createDynamicLink({ service, washingType }) {
  let link
  if (washingType === 'waterlessWash') {
    link = (`https://chillcarservices.page.link/?link=${encodeURIComponent(`https://chillapp.ae?route=${service?.key}&washingType=${washingType}`)}&apn=com.chillcarservices.app&isi=1550899765&ibi=com.chillapp.ios&utm_medium=share_service&st=${service?.name} 🎉&sd=book you services with Chillapp&si=${encodeURIComponent(service?.Service_image?.[0])}`)
  } else {
    link = (`https://chillcarservices.page.link/?link=${encodeURIComponent(`https://chillapp.ae?route=${service?.key}`)}&apn=com.chillcarservices.app&isi=1550899765&ibi=com.chillapp.ios&utm_medium=share_service&st=${service?.name} 🎉&sd=book you services with Chillapp&si=${encodeURIComponent(service?.Service_image?.[0])}`)
  }
  let results = await axios.post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCExglJjHXgXzSGHJB1NmLll7NgjOG8_bc', {
    'longDynamicLink': link,
    "suffix": {
      "option": "SHORT"
    },

  })

  return results?.data?.shortLink
}
export async function createVoucherDynamicLink({ voucher }) {
  let { amount, numberOfSplits, minAmount, exp, key } = voucher
  const chillLogo = "https://firebasestorage.googleapis.com/v0/b/chill-wash.appspot.com/o/FCMImages%2F120.png?alt=media&token=69e3ba41-1805-45c6-83a5-e809f16efe7f";
  const title = `Claim Your AED ${amount} Discount Now!`
  const description = `Use this AED ${amount} voucher on your next Chillapp booking and experience premium service for less!`
  let link = (`https://chillcarservices.page.link/?link=${encodeURIComponent(`https://chillapp.ae?route=voucher&voucherID=${key}&voucherValue=${amount}&splits=${numberOfSplits}&minOrderValue=${minAmount}&expiry=${exp}`)}&apn=com.chillcarservices.app&isi=1550899765&ibi=com.chillapp.ios&utm_medium=share_voucher&st=${title} 🎉&sd=${description}&si=${encodeURIComponent(chillLogo)}`);
  let results = await axios.post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCExglJjHXgXzSGHJB1NmLll7NgjOG8_bc', {
    'longDynamicLink': link,
    "suffix": {
      "option": "SHORT"
    },

  })

  return results?.data?.shortLink
}
export function requestPermission() {
  console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    }
  })

}

export function FormatTimestamp(timestamp) {
  if (!timestamp) {
    return ''

  }
  if (timestamp.seconds) {
    timestamp = timestamp.seconds * 1000
  }
  const now = moment();
  const date = moment(timestamp);
  let formatedTime = date.format('hh:mm A')

  // Check if the date is today
  if (date.isSame(now, 'day')) {
    return `${formatedTime}`;
  }

  // Check if the date is yesterday
  if (date.isSame(now.clone().subtract(1, 'day'), 'day')) {
    // return `Yesterday ${formatedTime}`;
    return `Yesterday`;
  }

  // Format the date as you like (e.g., 'DD/MM/YYYY')
  // return date.format('DD MMM hh:mm a');
  return date.format('DD/MM/YYYY');
}

export function sanitizeString(str) {
  // Replace commas, newlines, and double quotes with spaces
  // These are common characters that can cause issues in CSV files
  if (!str) {
    return ''
  }
  return str.replace(/[,"\n\r]+/g, ' ');
}
export function getPaymentMethod({ paymentType, price }) {
  if (price?.code === 'wallet' && Number(price?.totalPrice) === 0) {
    return 'wallet';
  }
  if (price?.code === 'wallet' && Number(price?.totalPrice) > 0) {
    return 'wallet / online';
  }
  return paymentType;
}


export const getTitle = (transaction) => {
  switch (transaction.type) {
    case 'earning':
      return transaction.referal ? 'Referral Points' : 'Cashback Bonus';
    case 'refund':
      return 'Refunded Points';
    case 'credit':
      if (transaction?.transfered) {
        return 'Bank Transfer';
      }
      return 'Credit';
    case 'voucher':
      if (transaction?.redeemed) {
        return `${transaction?.description || 'Voucher'} Redeemed`;
      }
      return transaction.description || 'Voucher';
    default:
      return 'Expenses';
  }
};

export const getDescription = (transaction) => {
  let exp = moment(GetEXP(transaction));
  if (['earning', 'credit', 'refund', 'voucher'].includes(transaction.type)) {
    if (transaction.redeemed) {
      return `Redeemed on ${moment(transaction.redeemed).format('DD, MMM YYYY')}`;
    } else if (transaction.transfered) {
      return `Transferred on ${moment(transaction.transfered).format('DD, MMM YYYY')}`;
    }
    return `Expires On ${exp.format('DD, MMM YYYY')}`;
  }
  return transaction.description;
};
export const GetEXP = (date) => {
  if (date?.exp) {
    return date?.exp
  }
  return moment(date?.created).add(3, 'months').valueOf()
}
export const getAmountColor = (transaction) => {
  return ['earning', 'refund', 'credit', 'voucher'].includes(transaction.type) && !transaction.redeemed && !transaction.transfered
    ? 'green'
    : '#F21F1F';
};

export const getAmountSign = (transaction) => {
  return ['earning', 'refund', 'credit', 'voucher'].includes(transaction.type) && !transaction.redeemed && !transaction.transfered
    ? '+'
    : '-';
};

export const CalculatePoints = (snap) => {
  let sorted = snap.sort((a, b) => a.created - b.created);
  const result = sorted?.reduce((val, current) => {
    let { type, amount } = current;
    amount = amount * 1;
    let increment = ['credit', 'refund', 'earning', 'voucher'].includes(type);
    let exp = GetEXP(current);
    let expired = moment().isAfter(moment(exp)) && ['credit', 'voucher'].includes(type);
    if (expired || current?.transfered || current?.redeemed) {
      return val
    }
    if (['earning', 'refund', 'expense'].includes(type)) {
      if (increment) {
        val.expired.push({ exp: { start: current.created, end: exp }, amount, OA: amount, id: current.id });
      } else {
        //check if recored created stamp is less than 3 months
        let eligible = val.expired.filter(rec => moment(current.created).isBetween(moment(rec.exp.start), moment(rec.exp.end)));
        let balance = amount;
        eligible.map(rec => {
          let index = val.expired.findIndex(r => r.id === rec.id);
          if (balance >= val.expired[index].amount) {
            balance = balance - val.expired[index].amount;
            val.expired[index].amount = 0;
          } else {
            val.expired[index].amount = val.expired[index].amount - balance;
            balance = 0;
          }
          return rec; // Ensure a value is returned
        });
      }
    }
    if (['credit', 'redeemed'].includes(type)) {
      val.totalCredit = val.totalCredit + (increment ? amount : -amount);
    }
    if (['voucher'].includes(type)) {
      val.totalVouchers = val.totalVouchers + (increment ? amount : -amount);
      if (!current.redeemed) {
        val.vouchers.push(current)
      }
    }
    return val
  }, {
    totalCashback: 0,
    totalCredit: 0,
    totalVouchers: 0,
    vouchers: [],
    expired: [],
  });
  result.totalCashback = result.expired.reduce((total, rec) => {
    if (moment(rec.exp.end).isAfter(moment())) {
      total = total + rec.amount
    }
    return total
  }, 0);
  result.totalCashback = Math.max(0, result.totalCashback)
  result.totalCredit = Math.max(0, result.totalCredit)
  result.totalVouchers = Math.max(0, result.totalVouchers)

  result.total = result.totalCashback + result.totalCredit + result.totalVouchers;
  result.totalWasherTip = Number(result.totalCredit) * 0.9705
  result.totalCheckoutPoints = result.totalCashback;
  return result

}
export const Capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const validateNumber = (countryCode, customer_number) => {
  let number = String(customer_number).replace(/\s/g, '');

  if (String(number).startsWith("0")) {
    number = Number(customer_number)
  }

  if (String(number).startsWith("971")) {
    var temp = String(number).substring(3, String(number).length);
    number = temp;
  }
  return ('+' + countryCode + number)
}
export const checkPhoneNumberVisibility = async ({ request, waitingTime }) => {

  const checkShowCallNumber = (lastMessageData, customer_number) => {
    if (!lastMessageData || (lastMessageData?.role && !['admin', 'washer'].includes(lastMessageData?.role))) return false;
    if (lastMessageData?.from === customer_number || (lastMessageData?.template_name && lastMessageData?.template_name !== 'create_new_session')) {
      return false;
    }
    const messageAge = moment().diff(moment(lastMessageData.created), 'minutes');
    return messageAge >= waitingTime;
  };

  if (!request?.customer_number) {
    return { showNumber: false, chatExists: false };
  }

  const number = Number(validateNumber((request.country_code || '971'), request.customer_number));
  const chatDocRef = firestore().collection('chats').doc(String(number));

  try {
    const chatDoc = await chatDocRef.get();
    if (!chatDoc.exists) {
      return { showNumber: ['new', 'accepted', 'active']?.includes(request.status) ? true : false, chatExists: false };
    }

    const messagesSnapshot = await chatDocRef.collection('messages').orderBy('created', 'desc').limit(1).get();
    if (messagesSnapshot.empty) {
      return { showNumber: ['new', 'accepted', 'active']?.includes(request.status) ? true : false, chatExists: chatDoc.data().whatsappActive || false };
    }

    const lastMessageData = messagesSnapshot.docs[0].data();
    const showNumber = ['new', 'accepted', 'active']?.includes(request.status) ? checkShowCallNumber(lastMessageData, String(number)) : false;

    return { showNumber, chatExists: chatDoc.data().whatsappActive || false };
  } catch (error) {
    console.error('Error checking phone number visibility:', error);
    return { showNumber: true, chatExists: false };
  }
};

