import moment from "moment";
import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import Select from 'react-select';
import ReactTable from "react-table";
import { Button, Card, CardBody, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledDropdown } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Rating from "../../../components/common/Rating";
import { firestore } from '../../../helpers/Firebase';
import './crmstyles.css';

import DataTablePagination from "../../../components/DatatablePagination";
import { accessTypes, getPaymentMethod, getValueBetweenParentheses, sanitizeString } from "../../../helpers/Utils";
import OrderModal from "../appointments/components/order";
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
const deviceWidth = () => {
  const width = window.innerWidth;
  const breakpoint = 820;
  return width < breakpoint ? 'mobile' : 'desktop';
}

const canceledByOptions = [
  {
    label: 'All', value: ['Super Admin',
      'Service Provider',
      'Worker',
      'Customer',
      'Super admin',
      'Admin',
      'Finance',
      'Customer Service',
      'Provider',
    ]
  },
  { label: 'Super Admin', value: ['Super Admin', 'Super admin', 'Finance', 'Customer Service', 'Admin'] },
  { label: 'Service Provider', value: ['Service Provider', 'Provider'] },
  { label: 'Worker', value: ['Worker'] },
  { label: 'Customer', value: ['Customer'] },
]
const canceledByReasons = [
  { label: 'All', value: ['Worker Busy', 'Traffic', 'Other'] },
  { label: 'Worker Busy', value: ['Worker Busy'] },
  { label: 'Traffic', value: ['Traffic'] },
  { label: 'Other', value: ['Other'] },
]
const canceledByCustomerReasons = [
  { label: 'All', value: ['Arriving late', 'Reschedule', 'Cancel from service provider', 'Other'] },
  { label: 'Arriving late', value: ['Arriving late'] },
  { label: 'Reschedule', value: ['Reschedule'] },
  { label: 'Cancel from service provider', value: ['Cancel from service provider'] },
  { label: 'Other', value: ['Other'] },
]

const statusOptions = [
  { label: 'All', value: ['new', 'pending', 'accepted', 'active', 'completed', 'rated', 'notRated', 'rejected', 'canceled'] },
  { label: 'Pending Orders', value: ['new', 'pending'] },
  { label: 'Accepted Orders', value: ['accepted'] },
  { label: 'In Process Orders', value: ['active'] },
  { label: 'Completed Orders', value: ['completed', 'notRated'] },
  { label: 'Rated Orders', value: ['rated'] },
  { label: 'Rejected Orders', value: ['rejected'] },
  { label: 'Canceled Orders', value: ['canceled'] },
]
const typeOptions = [
  { label: 'Online Orders', value: 'onlineBooking' },
  { label: 'Offline Orders', value: 'offlineBooking' },

]
const washingTypes = [
  { label: 'All', value: ['pressureWash', 'waterlessWash'], key: 'all' },
  { label: 'Pressure Wash', value: 'Pressure Wash', key: 'pressureWash' },
  { label: 'Waterless Wash', value: 'Waterless Wash', key: 'waterlessWash' },
]
const startDate = new Date();
startDate.setDate(startDate.getDate());
// startDate.setMonth(startDate.getMonth() - 1);

class OrderHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      serviceProvidersSelected: null,
      statusSelected: null,
      canceledBySelected: null,
      cancelReasonsSelected: null,
      typesSelected: null,
      washingTypesSelected: null,
      servicesSelected: null,
      workersSelected: null,
      dateRange: [startDate, new Date()],
      bookings: [],
      filteredOrders: null,
      sales: 0,
      avgSales: 0,
      modalBack: false,
      activeServices: {},
      selectedItem: {},
      numberSearch: '',
      mobileNumberSearch: '',
      loading: true,
      deleteBooking: false,
      allServiceProviders: {},
      addToArchive: true

    };
  }
  MyStatusCell(props) {
    let { status, reviewData, canceledBy, cancelReason } = props.original;

    const getStatusClassName = (status) => {
      const classNameMap = {
        new: 'pendingStatusfieldText',
        pending: 'pendingStatusfieldText',
        accepted: 'acceptedStatusfieldText',
        active: 'processStatusfieldText',
        completed: 'completedStatusfieldText',
        notRated: 'completedStatusfieldText',
        rejected: 'rejectedStatusfieldText',
        rated: 'ratedStatusfieldText',
        canceled: 'rejectedStatusfieldText',
      };
      return classNameMap[status] || '';
    };

    const renderStatusText = () => {
      if (status === 'new') {
        return 'Pending';
      }
      if (status === 'notRated') {
        return 'Completed';
      }
      if (reviewData && status === 'rated') {
        return (
          <div className='hoverRating'>
            {capitalize(status)}
            <i style={{ color: '#4a4a4a', marginLeft: '4px' }} className="simple-icon-info" /><br />
            <Rating total={5} rating={reviewData?.score} />
            <div className='ratingComment2'>
              {reviewData?.comment || 'No Comment'}
              {reviewData?.score ? <div >Average Rate: {reviewData?.score}</div> : ''}
              {reviewData?.serviceRate ? <div className="d-flex align-items-center">Service Rate: <Rating total={5} rating={reviewData?.serviceRate} style={{ margin: '4px 8px' }} /></div> : ''}
              {reviewData?.workerRate ? <div className="d-flex align-items-center">Worker Rate: <Rating total={5} rating={reviewData?.workerRate} style={{ margin: '4px 8px' }} /></div> : ''}
              {reviewData?.arrivalTimingRate ? <div className="d-flex align-items-center">Arrival Timing Rate: <Rating total={5} rating={reviewData?.arrivalTimingRate} style={{ margin: '4px 8px' }} /></div> : ''}
            </div>
          </div>
        );
      }
      if (status === 'canceled' || status === 'rejected') {
        return (
          <div className='hoverCanceled'>
            {capitalize(status)}
            <i style={{ color: '#4a4a4a', marginLeft: '4px' }} className="simple-icon-info" /><br />
            <div className='canceledComment2'>
              {canceledBy && (
                <div>
                  {status === 'rejected' ? 'Rejected by:' : 'Canceled By:'} {canceledBy} <br />
                  {cancelReason ? `Reason: ${cancelReason}` : 'No Comment'}
                </div>
              )}
              {!canceledBy && 'No Comment'}
            </div>
          </div>
        );
      }
      return capitalize(status);
    };

    const className = getStatusClassName(status);
    const statusText = renderStatusText();

    return <div className={className}>{statusText}</div>;
  }
  getOriginalData = (key) => {
    let { filteredOrders } = this.state
    let item = filteredOrders?.find(i => i?.key === key)
    return item
  }
  dataTableColumns() {
    const { authUser } = this.props;
    return ([
      {
        Header: "Order Number",
        accessor: "orderNumber",
        Cell: props => <p className="list-item-heading custoumClass">{props.value}</p>
      },
      {
        Header: "Customer",
        accessor: "customer_name",
        show: !['marketing'].includes(authUser?.user?.accessType),
        Cell: props => <p className="list-item-heading custoumClass">{props.value}<br />{accessTypes?.includes(authUser?.user?.accessType) ? props.original.customer_number : ''}</p>
      },
      {
        Header: "Service",
        accessor: "service_name",
        Cell: props => <p className="list-item-heading custoumClass">{props.value}</p>
      },
      {
        Header: "Provider",
        accessor: "sp_name",
        show: (accessTypes.includes(authUser?.user?.accessType)) ? true : false,
        Cell: props => <p className="list-item-heading custoumClass">{props.value}</p>
      },
      {
        Header: "Worker",
        accessor: "washer_name",
        Cell: props => <p className="list-item-heading custoumClass">{props.value}</p>
      },
      {
        Header: "Total",
        accessor: "totalPrice",
        sortable: true,
        show: !['marketing'].includes(authUser?.user?.accessType),
        sortMethod: (a, b) => {
          return a - b;
        },
        Cell: props => <p className="list-item-heading custoumClass">{"AED " + Number(props.value).toFixed(2)}</p>
      },
      {
        Header: "Status",
        accessor: "key",
        sortable: true,

        sortMethod: (a, b) => {
          const originalA = this.getOriginalData(a);
          const originalB = this.getOriginalData(b);
          if (originalA.status === originalB.status) {
            return originalB?.reviewData?.score - originalA?.reviewData?.score;; // Sort by review score within the same status
          }
          return originalA?.status.localeCompare(originalB?.status); // 
        },
        width: deviceWidth() === 'mobile' ? 200 : 100,
        Cell: props => {
          return (this.MyStatusCell(props)
          )
        }
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: props => <p className="list-item-heading custoumClass">{props.value === 'offlineBooking' ? `Offline` : 'Online'}</p>
      },
      {
        Header: "Appointment Date",
        accessor: 'bookingDate',
        Cell: props => <div className="list-item-heading custoumClass" >{moment(props.value).format("DD/MM/YYYY")}<div style={{ fontSize: '10px' }}>{moment(props.value).format("hh:mmA")}</div></div>
      },
      {
        sortable: false,
        accessor: "delete",
        show: (authUser?.user?.accessType === 'superAdmin') ? true : false,
        width: 80,
        Cell: props => <div >
          <Button
            style={{ padding: '0.2rem 1rem' }}
            color="danger"
            size="sm"
            outline
            // className="top-right-button"
            onClick={() => this.setState({ selectedBooking: props.original, deleteBooking: true }, () => {
            })}
          >Delete
          </Button>
        </div>
      },
      {
        sortable: false,
        accessor: "view",
        width: 80,
        show: !['marketing'].includes(authUser?.user?.accessType),

        Cell: props => <div >
          <Button
            style={{ padding: '0.2rem 1rem' }}
            color="primary"
            size="sm"
            outline
            // className="top-right-button"
            onClick={() =>
              this.setState({
                selectedItem: props.original,
                selected: props.index,
                modalBack: true
              })}
          >View
          </Button>
        </div>
      },
    ])
  }
  update = () => {
    this.setState({
      modalBack: !this.state.modalBack,
      loading: true
    }, () => {
      this.searchFunction()
    });
  };
  toggleModal = () => {
    this.setState({
      modalBack: !this.state.modalBack,
    });
  };
  dismiss = () => {
    this.setState({
      modalBack: !this.state.modalBack,
      selectedItem: {},
      editBooking: false
    });
  };
  toggleBack = () => {
    this.setState(prevState => ({
      modalBack: !prevState.modalBack
    }));
  };

  // componentWillReceiveProps(prop) {
  //   let { bookings } = prop;
  //   this.getOrders(bookings)
  // }
  componentDidMount() {
    this.getServices()
    this.searchFunction()
  }
  componentDidUpdate(previousProps) {
    let { services, advServices } = this.props
    if (previousProps.services !== services || previousProps.advServices !== advServices) {
      this.getServices()
    }
  }

  getServices() {
    let { activeServices } = this.state
    let { washers, services, advServices } = this.props
    let _this = this
    if (services && advServices) {
      let allServices = Object.values(services).concat(Object.values(advServices))
      if (washers && services) {
        Object.values(washers).map(washer => {
          return (
            (washer.activeServices) && (Object.keys(washer.activeServices).map(item => {
              return (
                // eslint-disable-next-line
                allServices.map(service => {
                  if (service.key === item) {
                    activeServices[service.key] = { ...service, modifier: washer.activeServices[item], label: service.name, value: service.name }
                    _this.setState({ activeServices })
                  }
                })
              )
            }))
          )
        })
      }
    }
  }
  filterFunc() {
    let { serviceProvidersSelected, servicesSelected, workersSelected, statusSelected, canceledBySelected, cancelReasonsSelected, typesSelected, washingTypesSelected, filteredOrders, numberSearch, mobileNumberSearch } = this.state


    if (serviceProvidersSelected && serviceProvidersSelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        let providersKeys = serviceProvidersSelected.map(item => {
          return item.key
        })
        return providersKeys.includes(item.sp_id)
      })
    }
    if (servicesSelected && servicesSelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        return servicesSelected.includes(item.service_id)
      })
    }
    if (workersSelected && workersSelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        return workersSelected.includes(item.washer_id)
      })
    }
    if (statusSelected && statusSelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        return statusSelected?.includes(item.status)
      })
    }
    if (canceledBySelected && canceledBySelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        return canceledBySelected?.includes(getValueBetweenParentheses(item.canceledBy)) && (item.status === 'canceled' || item.status === 'rejected')
      })
    }
    if (cancelReasonsSelected && cancelReasonsSelected.length) {
      let otherReasons = filteredOrders.map(item => {
        return !['Worker Busy', 'Traffic', 'Arriving late', 'Reschedule', 'Cancel from service provider'].includes(item.cancelReason) && (item.status === 'canceled' || item.status === 'rejected') ? item.cancelReason : false
      })

      if (cancelReasonsSelected.includes('Other')) {
        cancelReasonsSelected = [...cancelReasonsSelected, ...otherReasons]
      }
      filteredOrders = filteredOrders.filter((item) => {
        return cancelReasonsSelected?.includes(item?.cancelReason) && (item.status === 'canceled' || item.status === 'rejected')
      })
    }

    if (typesSelected && typesSelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        return typesSelected.includes(item.type)
      })
    }
    if (washingTypesSelected && washingTypesSelected.length) {
      filteredOrders = filteredOrders.filter((item) => {
        const itemWashingType = item?.washingType || "pressureWash";
        return washingTypesSelected.includes(itemWashingType);
      })
    }
    if (numberSearch) {
      filteredOrders = filteredOrders.filter((item) => {
        return (String(item.orderNumber)).includes(numberSearch)
      })
    }
    if (mobileNumberSearch) {
      filteredOrders = filteredOrders.filter((item) => {
        return (String(item.customer_number)).includes(mobileNumberSearch)
      })
    }


    let sales = 0
    let avgSales = 0
    filteredOrders.map(item => {
      return (
        (item.status !== 'canceled' && item.status !== 'rejected') && (
          sales += Number(item.totalPrice),
          avgSales = (sales / filteredOrders.length).toFixed(2)
        )
      )

    })
    this.setState({ filteredOrders, sales, avgSales })

  }
  handleNumberChange(event) {
    let { bookings } = this.state

    switch (event.target.name) {
      case 'number':
        this.setState({ numberSearch: event.target.value, filteredOrders: bookings, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      case 'mobileNumber':
        this.setState({ mobileNumberSearch: event.target.value, filteredOrders: bookings, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      default:
        break;
    }


  }
  searchFunction = async () => {

    let { authUser } = this.props;
    let { dateRange } = this.state;

    let ref = firestore().collection('bookings')
    if (!accessTypes.includes(authUser?.user?.accessType)) {
      ref = ref.where("sp_id", "==", authUser?.user?.uid)
    }
    let userBookingsSnap = await ref.where('bookingDate', '>=', moment(dateRange[0]).startOf('day').toDate().getTime()).where('bookingDate', '<=', moment(dateRange[1]).endOf('day').toDate().getTime()).get()
    let asyncOptionsOnline = userBookingsSnap.docs?.filter(r => !r.data()?.subscription).map(async res => {
      let reviewsDataSnap;
      let reviewData = {}
      if (res.data()?.status === 'rated') {
        reviewsDataSnap = await firestore().collection('reviews').where('booking_id', '==', res.id).get()
        reviewData = reviewsDataSnap?.docs[0]?.data()
      }

      return {
        ...res.data(),
        key: res.id,
        bookingId: res.id,
        reviewData: reviewsDataSnap?.docs[0]?.exists ? reviewData : {},
      }

    })

    Promise.all(asyncOptionsOnline).then((ress) => {
      let sales = 0
      let avgSales = 0
      ress.map(item => {
        return (
          (item.status !== 'canceled' && item.status !== 'rejected') && (
            sales += Number(item?.totalPrice || 0),
            avgSales = (sales / ress.length).toFixed(2)
          )
        )

      })
      this.setState({ bookings: ress, filteredOrders: ress, avgSales, sales, loading: false }, () => {
        this.filterFunc()
      })
    })


  }


  handleChange(value, type) {
    let { bookings } = this.state

    switch (type.name) {

      case 'services':
        let servicesArray = []
        if (value) {
          value.map(item => {
            return (
              servicesArray.push(item.key)
            )
          })
        }
        this.setState({ servicesSelected: servicesArray, filteredOrders: bookings, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      case 'workers':
        let workersArray = []
        if (value) {
          value.map(item => {
            return (
              workersArray.push(item.key)
            )
          })
        }
        this.setState({ workersSelected: workersArray, filteredOrders: bookings, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      case 'status':
        let statusArray = []
        if (value) {
          value.map(item => {
            return (
              statusArray = statusArray.concat(item.value)
            )
          })
        }
        this.setState({ statusSelected: statusArray, filteredOrders: bookings, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      case 'canceledBy':
        let canceledByArray = []
        if (value) {
          value.map(item => {
            return (
              canceledByArray = canceledByArray.concat(item.value)
            )
          })
        }
        this.setState({ canceledBySelected: canceledByArray, cancelReasonsSelected: null, filteredOrders: bookings, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      case 'cancelReason':
        let cancelReasonArray = []
        if (value) {
          value.map(item => {
            return (
              cancelReasonArray = cancelReasonArray.concat(item.value)
            )
          })
        }
        this.setState({ cancelReasonsSelected: cancelReasonArray, filteredOrders: bookings, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      case 'type':
        let typesArray = []
        if (value) {
          value.map(item => {
            return (
              typesArray.push(item.value)
            )
          })
        }
        this.setState({ typesSelected: typesArray, filteredOrders: bookings, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      case 'providers':
        let providersArray = []

        if (value) {
          value.map(item => {
            return (
              item.key === 'allProviders' ? providersArray = item.value :
                providersArray.push(item)
            )
          })

        }
        this.setState({ serviceProvidersSelected: providersArray?.length ? providersArray : null, filteredOrders: bookings, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;

      case 'washingType':
        let washingTypesArray = []
        if (value) {
          value.map(item => {
            return (
              item.key === 'all' ? washingTypesArray = item.value :
                washingTypesArray.push(item.key)
            )
          })
        }
        this.setState({ washingTypesSelected: washingTypesArray, filteredOrders: bookings, sales: 0, avgSales: 0 }, () => {
          this.filterFunc()
        })
        break;
      default:
        break;
    }
  }

  getRefundedRequests = async () => {
    let { filteredOrders } = this.state
    this.setState({ loading: true })
    let ordersIds = filteredOrders.map(item => item.key)
    const chunkArray = (array, size) => {
      const result = [];
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    };
    const chunks = chunkArray(ordersIds, 10);

    const promises = chunks.map(chunk =>
      firestore().collectionGroup('walletLog').where('transfered', '!=', null).where('bookingID', 'in', chunk).get()
    );
    const querySnapshots = await Promise.all(promises);

    const combinedResults = [];
    querySnapshots.forEach(snapshot => {
      snapshot.forEach(doc => {
        combinedResults.push({ id: doc.id, ...doc.data() });
      });
    });
    let refunds = combinedResults
    let refundedOrders = filteredOrders?.filter(order => refunds.some(refund => refund.bookingID === order.key))
    return refundedOrders
    // return combinedResults;
  }




  exportCSV({ orders, type = 'orders' }) {
    const { authUser, providers } = this.props;
    const csvRows = [];
    let headers = []
    headers = [
      'Order Ref.',
      'Created At',
      'Date of Service',
      'Name of Customer',
      'Customer Number',
      'Customer Location',
      'Service Provider',
      'Service Provider ID',
      'IBAN',
      'Account Number',
      'Account Name',
      'Bank Name',
      'Joining Date',
      'Exipry Date',
      'Renewal Date',
      'Comission Rate',
      'Rejection Rate',
      'Worker',
      'Types of service',
      'Type of Car/Vehicle',
      'Number of Washes',
      'Status',
      'Rescheduled',
      'Canceled by',
      'Cancel reason',
      'Super admin comment',
      'Cancelation Fees Applied',
      'Average Rate',
      'Service Rate',
      'Worker Rate',
      'Arrival Timing Rate',
      'Rating Comment',
      'type',
      'Standard Fees',
      'Addons Price',
      'Total Discounts',
      'Wallet credit Discount',
      'Loyalty Points Discount',
      'Promo code Discount',
      'Voucher Discount',
      'Discount Code',
      'Promo Code',
      'Total Fees',
      'Worker Tips',
      'Chill Comission',
      'Rejection Fees',
      'Payment Type',
      'Transaction ID',
      'Worker Tip Transaction ID',
      'Charges',
      'Net fees'];
    if (!accessTypes.includes(authUser?.user?.accessType)) {
      headers.splice(3, 1);
    }
    // Add header row to CSV
    csvRows.push(headers.join(','));

    // Loop through each order and add it to the CSV
    for (const order of orders) {
      let carType = ""
      if (order.carDetail.length) {
        (order?.carDetail).map(car => {
          if (authUser?.user?.accessType === 'superAdmin') {
            return carType += `${car?.car_model} ${car?.modelNumber?.model} (${car?.modelNumber?.category}) /`
          }
          return (
            carType += `${car?.modelNumber?.category} /`
          )
        })
      } else {
        carType = order?.modelNumber?.category === "SUV" ? "SUV " : 'SEDAN '
      }
      let paymentType = order.paymentType
      let charges = order.paymentType === "card" ? (order.price?.totalPrice * 0.0295).toFixed(2) : "--"
      let fees = order.paymentType === "card" ? (order.price?.totalPrice - charges) : "--"
      let provider = providers?.[order?.sp_id] || {}
      let status = (["notRated"].includes(order.status) ? "Completed" : order.status === "new" ? "Pending" : order.status).toUpperCase()
      let type = order.type === "offlineBooking" ? "Offline" : "Online";

      const addressParts = order.place?.address.split(' - ');

      // Get the last two parts of the address
      const lastTwoParts = addressParts.slice(-2).join(' - ');

      let reschedule = order.reschedule || order?.adminReschedule ? true : '--'
      // let providerDetails = `Provider: ${order.sp_name}\n IBAN: ${provider?.bankDetails?.IBAN || ''}\n Account Number:${provider?.bankDetails?.accountNumber || ''}\n Account Name: ${provider?.bankDetails?.accountName || ''}\n Branch: ${provider?.bankDetails?.branch || ''}`

      const row = [
        order.orderNumber,
        moment(order.created).format("DD/MM/YYYY hh:mmA"),
        moment(order.bookingDate).format("DD/MM/YYYY hh:mmA"),
        order.customer_name,
        order.customer_number,
        sanitizeString(lastTwoParts) || "--",
        order.sp_name,
        provider.uniqueID,
        provider?.bankDetails?.IBAN || '--',
        provider?.bankDetails?.accountNumber || '--',
        provider?.bankDetails?.accountName || '--',
        provider?.bankDetails?.branch || '--',
        provider?.joiningDate ? moment(provider?.joiningDate).format("DD/MM/YYYY") : '--',
        provider?.expiryDate ? moment(provider?.expiryDate).format("DD/MM/YYYY") : '--',
        provider?.renewalDate ? moment(provider?.renewalDate).format("DD/MM/YYYY") : '--',
        provider?.commissionRate || '--',
        provider?.rejectionRate || '--',
        order?.washer_name,
        order.service_name,
        carType.slice(0, -1),
        order.carDetail.length,
        status,
        reschedule,
        sanitizeString(order?.canceledBy) || '--',
        sanitizeString(order?.cancelReason) || '--',
        sanitizeString(order?.superAdminComment) || '--',
        order?.cancellationApplied || order?.status === 'rejected' ? 'Applied' : '--',
        order?.rate || '--',
        order?.serviceRate || '--',
        order?.workerRate || '--',
        order?.arrivalTimingRate || '--',
        sanitizeString(order?.reason) || '--',
        type,
        Number(order.price?.servicePrice) + Number(order.price?.variationPrice || 0),
        order.price.addonsPrice ? order.price.addonsPrice : '--',
        order?.price?.discount || "--",
        order?.price?.redeemedCredit || "--",
        order?.price?.redeemedPoints || "--",
        order?.price?.redeemedPromoCode || "--",
        order?.price?.redeemedVoucher || "--",
        order?.price?.code || "--",
        order?.price?.promoCode || "--",
        order.price?.totalPrice,
        order.price?.washerTip ? ((Number(order.price?.washerTip) / 100) * 0.9705).toFixed(2) : '--',
        provider?.commissionRate ? this.calculateChillComission(order) : '--',
        (order?.cancellationApplied || order?.status === 'rejected') && provider?.rejectionRate ? this.calculateRejectionComission(order) : '--',
        getPaymentMethod({ paymentType, price: order?.price }),
        order?.paymentMethod?.authorize_id || '--',
        order?.tip_washer?.authorize_id || '--',
        charges,
        fees
      ];
      if (!accessTypes.includes(authUser?.user?.accessType)) {
        row.splice(3, 1);
      }
      csvRows.push(row.join(','));
    }

    // Combine all rows into a single CSV string
    const csvString = csvRows.join('\n');

    // Create a download link for the CSV file
    const link = document.createElement('a');
    link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString);
    if (type === 'refunds') {
      link.download = 'refunds.csv';
    } else {
      link.download = 'orders.csv';
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.setState({ loading: false })
  }
  calculateChillComission = (order) => {
    const { providers } = this.props;
    const provider = providers?.[order?.sp_id] || {};
    const commissionRate = Number(provider?.commissionRate) / 100;
    let totalFees = Number(order.price?.totalPrice || 0);
    if (order?.price?.code === 'wallet') {
      totalFees = totalFees + Number(order.price?.redeemedCredit || 0)
    }
    const chillComission = totalFees * commissionRate;
    return Number(chillComission)?.toFixed(2);
  }
  calculateRejectionComission = (order) => {
    const { providers } = this.props;
    const provider = providers?.[order?.sp_id] || {};
    const rejectionRate = Number(provider?.rejectionRate) / 100;
    let totalFees = Number(order.price?.totalPrice || 0);
    if (order?.price?.code === 'wallet') {
      totalFees = totalFees + Number(order.price?.redeemedCredit || 0)
    }
    const chillComission = totalFees * rejectionRate;
    return Number(chillComission)?.toFixed(2);
  }
  render() {
    const { washers, authUser, providers } = this.props;
    let { bookings, filteredOrders, sales, avgSales, modalBack, selectedItem, dateRange, loading, deleteBooking, selectedBooking, activeServices, canceledBySelected, serviceProvidersSelected } = this.state
    let _this = this

    if (!bookings && !bookings.length) {
      return (
        <div className="d-flex flex-row mb-3 pb-3 text-center justify-content-center ">
          <div className='font-weight-bold'>No Orders yet!</div>
        </div>
      )
    }


    let allServiceProviders = {}
    if (providers && Object.keys(providers).length) {
      allServiceProviders.allProviders = { key: 'allProviders', value: Object.values(providers), label: "All Service Providers" }
      Object.keys(providers).map(rec => {
        return allServiceProviders[rec] = providers[rec]
      })
    }

    return (
      <Fragment>
        {(loading) &&
          <div className='loading'></div>
        }
        <Row>
          <Colxx xxs="12" >
            <div className='d-flex justify-content-between'>
              <div className="mb-2"><h1>Orders</h1></div>
              {/* {!['marketing'].includes(authUser?.user?.accessType) && <Button className='btn-sm' style={{ height: '35px' }} onClick={() => this.exportCSV()}>Export</Button>} */}
              {!['marketing'].includes(authUser?.user?.accessType) && <UncontrolledDropdown className="dropdown-menu-right">
                <DropdownToggle className="p-0" color="empty">
                  <Button className='btn-sm' style={{ height: '35px' }}>Export</Button>
                </DropdownToggle>
                <DropdownMenu className="mt-3" right>
                  <DropdownItem onClick={() => this.exportCSV({ orders: filteredOrders, type: 'orders' })}>Export Orders</DropdownItem>
                  <DropdownItem onClick={async () => {
                    let refundedOrders = await this.getRefundedRequests()
                    this.setState({ loading: false })
                    this.exportCSV({ orders: refundedOrders, type: 'refunds' })
                  }}>Export Refunds</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>}
            </div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row style={{ zIndex: '100000' }}>
          <Colxx xxs="12">
            <Card className="mb-4 rounded">
              <CardBody>
                <Row>
                  {[
                    { key: 'date', placeholder: 'Status', xxs: '12', type: 'datepicker', md: accessTypes.includes(authUser?.user?.accessType) ? "3" : '4' },
                    { key: 'providers', data: allServiceProviders && Object.values(allServiceProviders), value: serviceProvidersSelected && Object.values(serviceProvidersSelected), placeholder: 'Service Providers', xxs: '12', type: 'select', hide: !accessTypes.includes(authUser?.user?.accessType) },
                    { key: 'number', placeholder: 'Order Number', xxs: '6', type: 'number', md: accessTypes.includes(authUser?.user?.accessType) ? "3" : '4' },
                    { key: 'mobileNumber', placeholder: 'Mobile Number', xxs: '6', type: 'mobileNumber', md: accessTypes.includes(authUser?.user?.accessType) ? "3" : '4', hide: !accessTypes.includes(authUser?.user?.accessType) },
                    { key: 'status', data: statusOptions, placeholder: 'Status', xxs: '6', md: accessTypes.includes(authUser?.user?.accessType) ? "3" : '4', type: 'select' },
                    { key: 'type', data: typeOptions, placeholder: 'Type', xxs: '6', md: accessTypes.includes(authUser?.user?.accessType) ? "3" : '4', type: 'select' },
                    { key: 'services', data: activeServices && Object.values(activeServices), placeholder: 'Services', xxs: '6', md: accessTypes.includes(authUser?.user?.accessType) ? "3" : '4', type: 'select' },
                    { key: 'washingType', data: washingTypes, placeholder: 'Wash Type', xxs: '6', md: accessTypes.includes(authUser?.user?.accessType) ? "3" : '4', type: 'select' },
                    { key: 'workers', data: washers && Object.values(washers)?.filter(w => serviceProvidersSelected ? Object.values(serviceProvidersSelected || {})?.map(s => s.key).includes(w.sp_id) : true), placeholder: 'Workers', xxs: '6', md: accessTypes.includes(authUser?.user?.accessType) ? "3" : '4', type: 'select' },
                    { key: 'canceledBy', data: canceledByOptions, placeholder: 'Canceled By', xxs: '12', hide: !accessTypes?.includes(authUser?.user?.accessType), type: 'select' },
                    { key: 'cancelReason', data: canceledBySelected?.includes('Customer') ? canceledByCustomerReasons : canceledByReasons, placeholder: 'Cancel Reason', xxs: '12', hide: !accessTypes?.includes(authUser?.user?.accessType) || canceledBySelected?.length !== 1 || canceledBySelected?.includes('Super Admin'), type: 'select' },

                  ].map((item, index) => {
                    return !item.hide && (
                      <Colxx key={index} className='mt-2' xxs={item.xxs} md={item.md || '3'}>
                        {item.type === 'select' ? <Select
                          placeholder={item.placeholder}
                          isMulti
                          name={item.key}
                          options={item.data}
                          value={item.value}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={this.handleChange.bind(this)}
                        /> : item.type === 'datepicker' ?
                          <DatePicker
                            onChange={(dates) => {
                              this.setState({ dateRange: dates }, () => {
                                if (dates[1]) {
                                  this.setState({ loading: true, selectEnds: true }, () => {
                                    this.searchFunction();
                                  })
                                }
                              })
                            }}
                            startDate={dateRange[0]}
                            endDate={dateRange[1]}
                            selectsRange={true}
                            dateFormat={'dd/MM/yyyy'}
                          /> :
                          <Input type={item.type}
                            style={{ borderRadius: '4px' }}
                            placeholder={item.placeholder}
                            name={item.key}
                            onChange={this.handleNumberChange.bind(this)} />
                        }
                      </Colxx>
                    )
                  })}
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card className="mb-4 rounded">
              <Row className="mt-4 pl-4 pr-4">
                {!['marketing'].includes(authUser?.user?.accessType) && <Colxx xxs="4" className='d-flex flex-column justify-content-center align-items-center'>
                  <div className="salesTitle">Revenue</div>
                  <div className="salesValue">{'AED ' + sales.toFixed(2)}</div>
                </Colxx>}
                {!['marketing'].includes(authUser?.user?.accessType) && <Colxx xxs="4" className='d-flex flex-column justify-content-center align-items-center'>
                  <div className="salesTitle">Average order value</div>
                  <div className="salesValue">{'AED ' + Number(avgSales).toFixed(2)}</div>
                </Colxx>}
                <Colxx xxs="4" className='d-flex flex-column justify-content-center align-items-center'>
                  <div className="salesTitle">Total orders</div>
                  <div className="salesValue">{filteredOrders ? filteredOrders.length : bookings.length}</div>
                </Colxx>
              </Row>
              <Separator className="mb-4 mt-4" />
              <CardBody style={{ paddingTop: '0' }}>
                <ReactTable
                  style={{ zIndex: '0' }}
                  // className='custuomReactTable'
                  data={filteredOrders ? filteredOrders : bookings}
                  columns={this.dataTableColumns()}
                  pageSize={filteredOrders?.length < 50 ? filteredOrders.length : (this.state.pageSize || 50)}
                  showPageJump={false}
                  pageSizeOptions={[20, 50, 60, 80, 100, 200]}
                  onPageSizeChange={(size) => {
                    this.setState({ pageSize: size })
                  }}
                  defaultPageSize={50}
                  PaginationComponent={DataTablePagination}
                  showPageSizeOptions={true}
                  showPagination={true}
                />
              </CardBody>
              {
                <Modal style={{ borderRadius: "16px", overflow: 'hidden' }} toggle={this.toggleBack} isOpen={this.state.modalBack}>
                  <ModalBody>
                    <OrderModal providers={providers}
                      bookings={bookings}
                      activeServices={activeServices}
                      washers={washers}
                      toggleModal={this.toggleModal}
                      dismiss={this.dismiss}
                      modalOpen={modalBack}
                      selectedBooking={selectedItem}
                      editBooking={true}
                      fromHistory={true}
                      update={this.update}
                    />
                  </ModalBody>
                </Modal>
              }
              <Modal
                isOpen={deleteBooking}
                backdrop="static">
                <ModalHeader >Delete Appointment</ModalHeader>
                <ModalBody>
                  Kindly enter the password to delete this appointment?
                  <Input type="password" value={this.state.password} className="mt-2" placeholder={'Password'} onChange={(e) => this.setState({ password: e.target.value })} />
                  <CustomInput
                    className="custom-checkbox mb-0 mt-1 d-inline-block"
                    type="checkbox"
                    id="checkAll"
                    checked={this.state.addToArchive}
                    onChange={(e) => {
                      this.setState({ addToArchive: e.target.checked })
                    }}
                    label={<div style={{ cursor: 'pointer', fontWeight: '700' }}>Add this order to archive</div>}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" outline onClick={() => this.setState({ deleteBooking: false, selectedBooking: {} })}>
                    Dismiss
                  </Button>
                  <Button disabled={(this.state.password !== '2021') || authUser?.user?.accessType !== 'superAdmin'} color="danger" onClick={() => {
                    if (selectedBooking?.key) {
                      firestore().collection('bookings').doc(selectedBooking.key).delete().then(function () {
                        filteredOrders = filteredOrders.filter(rec => rec.key !== selectedBooking.key)
                        if (_this.state.addToArchive) {
                          firestore().collection('archivedBookings').doc(selectedBooking.key).set(selectedBooking).then(function () {
                            _this.setState({ deleteBooking: false, selectedBooking: {}, filteredOrders })
                          }).catch(function (error) {
                            _this.setState({ deleteBooking: false, selectedBooking: {}, filteredOrders })

                            console.error("Error writing document: ", error);
                          });

                        } else {
                          _this.setState({ deleteBooking: false, selectedBooking: {}, filteredOrders })

                        }
                      })
                    }
                  }}>
                    Delete Appointment
                  </Button>{" "}
                </ModalFooter>
              </Modal>
            </Card>
          </Colxx>
        </Row>

      </Fragment>
    );
  }
}

const mapStateToProps = ({ providers, authUser }) => {
  return {
    authUser,
    providers: providers ? providers.provider : {},
    washers: providers ? providers.washers : {},
    services: providers ? providers.services : {},
    advServices: providers ? providers.advServices : {},
  };
};

export default connect(mapStateToProps)(OrderHistory);

